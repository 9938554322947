import React from "react";
import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import PageBreadcrumb from "../components/pagebreadcrumb";
import BlogGrid from "../container/recent/index";
import { normalizedData } from "@utils/functions";
import ProjectInfo from "../components/projectInfo";
import PortfolioImage from "../data/images/portfolio/Portfolio.png";
const Recentpage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO
                title="Portfolio"
                pathname="/portfolio"
                description="Woorg : The fastest growing performance marketing and advertising agency with clients around 5 continents in multiple industries. Connect now to boost your business."
            />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Portfolios"
                // decription="We love what we do, check out some of our latest works"
                backgroundImage={PortfolioImage}
            />
            <BlogGrid
                data={{
                    items: data.allArticle.nodes,
                }}
            />
            <ProjectInfo />
        </Layout>
    );
};
Recentpage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query recentpage {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    socials {
                        color
                        link
                        icon
                    }
                    ...Footer
                }
            }
        }
        allArticle(sort: { order: ASC, fields: rank }) {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Recentpage;
